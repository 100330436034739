(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/tournament-registration-form/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/tournament-registration-form/assets/javascripts/constants.js');
"use strict";


const GameTypes = [{
  id: 'texasholdem',
  title: "Texas Hold'em"
}, {
  id: 'omaha',
  title: 'Omaha'
}, {
  id: 'omahahilo',
  title: 'Omaha Hi/Lo'
}];
const Blinds = [{
  id: 'slow',
  title: 'Långsam (15 min)'
}, {
  id: 'standard',
  title: 'Vanlig (12 min)'
}, {
  id: 'fast',
  title: 'Snabb (6 min)'
}, {
  id: 'x-fast',
  title: 'X-snabb (3 min)'
}];
const Stack = [{
  id: '1000',
  title: '1\u2009000'
}, {
  id: '1500',
  title: '1\u2009500'
}, {
  id: '2000',
  title: '2\u2009000'
}, {
  id: '3000',
  title: '3\u2009000'
}, {
  id: '5000',
  title: '5\u2009000'
}, {
  id: '10000',
  title: '10\u2009000'
}];
const Buyin = [{
  id: '55',
  title: '50 + 5'
}, {
  id: '110',
  title: '100 + 10'
}, {
  id: '220',
  title: '200 + 20'
}, {
  id: '330',
  title: '300 + 30'
}, {
  id: '440',
  title: '400 + 40'
}, {
  id: '550',
  title: '500 + 50'
}, {
  id: '825',
  title: '750 + 75'
}, {
  id: '1090',
  title: '1\u2009000 + 100'
}];
const LateRegistration = [{
  id: 'blindlevel-1',
  title: 'Blindnivå 1'
}, {
  id: 'blindlevel-2',
  title: 'Blindnivå 2'
}, {
  id: 'blindlevel-3',
  title: 'Blindnivå 3'
}, {
  id: 'blindlevel-4',
  title: 'Blindnivå 4'
}, {
  id: 'blindlevel-5',
  title: 'Blindnivå 5'
}, {
  id: 'blindlevel-6',
  title: 'Blindnivå 6'
}, {
  id: 'blindlevel-7',
  title: 'Blindnivå 7'
}, {
  id: 'blindlevel-8',
  title: 'Blindnivå 8'
}, {
  id: 'blindlevel-9',
  title: 'Blindnivå 9'
}, {
  id: 'blindlevel-10',
  title: 'Blindnivå 10'
}];
const TimeBank = [{
  id: '10',
  title: '10 s'
}, {
  id: '20',
  title: '20 s'
}, {
  id: '30',
  title: '30 s'
}, {
  id: '40',
  title: '40 s'
}, {
  id: '50',
  title: '50 s'
}, {
  id: '60',
  title: '60 s'
}];
const ReEntry = [{
  id: '0',
  title: '0'
}, {
  id: '1',
  title: '1'
}, {
  id: '2',
  title: '2'
}, {
  id: '3',
  title: '3'
}];
const TableSize = [{
  id: '6',
  title: '6'
}, {
  id: '8',
  title: '8'
}, {
  id: '9',
  title: '9'
}];
setGlobal('svs.poker_react.components.tournamentRegistrationForm.constants', {
  GameTypes,
  Blinds,
  Stack,
  Buyin,
  LateRegistration,
  TimeBank,
  ReEntry,
  TableSize
});

 })(window);