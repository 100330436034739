(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/tournament-registration-form/assets/javascripts/tournament-select.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/tournament-registration-form/assets/javascripts/tournament-select.js');
"use strict";

const {
  ControlGroup,
  Select,
  Option
} = svs.ui.reactForm;
const TournamentSelect = _ref => {
  let {
    id,
    label,
    onChange,
    options,
    value
  } = _ref;
  return React.createElement(ControlGroup, {
    className: "col-xs-12 col-md-6",
    v2: true
  }, React.createElement(Select, {
    id: id,
    inverted: true,
    label: label,
    onChange: onChange,
    v2: true,
    value: value
  }, options.map(_ref2 => {
    let {
      id,
      title
    } = _ref2;
    return React.createElement(Option, {
      key: id,
      value: id
    }, title);
  })));
};
setGlobal('svs.poker_react.components.tournamentRegistrationForm.TournamentSelect', TournamentSelect);

 })(window);