(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/tournament-registration-form/assets/javascripts/registration-form-reducer.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/tournament-registration-form/assets/javascripts/registration-form-reducer.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  GameTypes,
  Blinds,
  Stack,
  Buyin,
  LateRegistration,
  TimeBank,
  ReEntry,
  TableSize
} = svs.poker_react.components.tournamentRegistrationForm.constants;
const actions = {
  SET_TOURNAMENT_NAME: 'SET_TOURNAMENT_NAME',
  SET_PASSWORD: 'SET_PASSWORD',
  SET_GAME_TYPE: 'SET_GAME_TYPE',
  SET_BLINDS: 'SET_BLINDS',
  SET_STACK: 'SET_STACK',
  SET_BUYIN: 'SET_BUYIN',
  SET_LATE_REGISTRATION: 'SET_LATE_REGISTRATION',
  SET_TIMEBANK: 'SET_TIMEBANK',
  SET_RE_ENTRY: 'SET_RE_ENTRY',
  SET_TABLE_SIZE: 'SET_TABLE_SIZE',
  SET_DATE: 'SET_DATE',
  SET_TIME: 'SET_TIME',
  SET_NAME: 'SET_NAME',
  SET_EMAIL: 'SET_EMAIL',
  RESET_FORM: 'RESET_FORM'
};
const initState = {
  name: svs.poker_react.tournamentRegistration.data.playerProperties.name || '',
  email: svs.poker_react.tournamentRegistration.data.playerProperties.email || '',
  tournamentName: '',
  password: '',
  blinds: Blinds[0].id,
  stack: Stack[0].id,
  buyin: Buyin[0].id,
  lateRegistration: LateRegistration[0].id,
  timeBank: TimeBank[0].id,
  reEntry: ReEntry[0].id,
  gameType: GameTypes[0].id,
  tableSize: TableSize[0].id,
  time: '21:00',
  date: dateFns.addDays(new Date(), 5).toISOString().split('T')[0]
};
const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_TOURNAMENT_NAME:
      return _objectSpread(_objectSpread({}, state), {}, {
        tournamentName: action.payload
      });
    case actions.SET_PASSWORD:
      return _objectSpread(_objectSpread({}, state), {}, {
        password: action.payload
      });
    case actions.SET_GAME_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, {
        gameType: action.payload
      });
    case actions.SET_BLINDS:
      return _objectSpread(_objectSpread({}, state), {}, {
        blinds: action.payload
      });
    case actions.SET_STACK:
      return _objectSpread(_objectSpread({}, state), {}, {
        stack: action.payload
      });
    case actions.SET_BUYIN:
      return _objectSpread(_objectSpread({}, state), {}, {
        buyin: action.payload
      });
    case actions.SET_LATE_REGISTRATION:
      return _objectSpread(_objectSpread({}, state), {}, {
        lateRegistration: action.payload
      });
    case actions.SET_TIMEBANK:
      return _objectSpread(_objectSpread({}, state), {}, {
        timeBank: action.payload
      });
    case actions.SET_RE_ENTRY:
      return _objectSpread(_objectSpread({}, state), {}, {
        reEntry: action.payload
      });
    case actions.SET_TABLE_SIZE:
      return _objectSpread(_objectSpread({}, state), {}, {
        tableSize: action.payload
      });
    case actions.SET_DATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        date: action.payload
      });
    case actions.SET_TIME:
      return _objectSpread(_objectSpread({}, state), {}, {
        time: action.payload
      });
    case actions.SET_NAME:
      return _objectSpread(_objectSpread({}, state), {}, {
        name: action.payload
      });
    case actions.SET_EMAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        email: action.payload
      });
    case actions.RESET_FORM:
      return _objectSpread(_objectSpread({}, state), initState);
    default:
      return state;
  }
};
setGlobal('svs.poker_react.components.tournamentRegistrationForm.reducer', {
  initState,
  reducer,
  actions
});

 })(window);