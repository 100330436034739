(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/tournament-registration-form/assets/javascripts/tournament-registration-form.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/tournament-registration-form/assets/javascripts/tournament-registration-form.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  useReducer,
  useState,
  useRef
} = React;
const {
  PokerPaper
} = svs.poker_react.components;
const {
  Form,
  ControlGroup,
  Inputv2: Input,
  RadioGroup,
  RadioButton,
  Fieldset
} = svs.ui.reactForm;
const {
  DateInput,
  TimeInput
} = svs.ui.reactFormInputs;
const {
  ReactButton: Button
} = svs.ui;
const {
  initState,
  reducer,
  actions
} = svs.poker_react.components.tournamentRegistrationForm.reducer;
const {
  TournamentSelect
} = svs.poker_react.components.tournamentRegistrationForm;
const {
  GameTypes,
  Blinds,
  Stack,
  Buyin,
  LateRegistration,
  TimeBank,
  ReEntry,
  TableSize
} = svs.poker_react.components.tournamentRegistrationForm.constants;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_MD
} = svs.components.lbUtils.useMediaQuery;
const {
  name: nameFromServer,
  email: emailFromServer
} = svs.poker_react.tournamentRegistration.data.playerProperties;
const {
  handleSubmit
} = svs.poker_react.components.tournamentRegistrationForm.utils;
const {
  validateEmail,
  validateDate,
  validateTime
} = svs.components.formValidation;
const TournamentRegistrationForm = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [fieldError, setFieldError] = useState({
    name: false,
    email: false,
    tournamentName: false,
    password: false,
    time: false,
    date: false
  });
  const [isPosting, setIsPosting] = useState(false);
  const formRef = useRef();
  const isLoggedIn = svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
  const specialCharacters = /[^A-ZÅÄÖa-zåäö0-9]/;
  const validateField = field => {
    let hasError;
    switch (field) {
      case 'name':
        hasError = state.name.length < 5;
        break;
      case 'email':
        hasError = !validateEmail(state.email);
        break;
      case 'tournamentName':
        hasError = state.tournamentName.trim().length < 4 || state.tournamentName.trim().length > 27 || specialCharacters.test(state.tournamentName.trim());
        break;
      case 'password':
        hasError = state.password.trim().length < 4 || state.password.trim().length > 8 || specialCharacters.test(state.password.trim());
        break;
      case 'time':
        hasError = !validateTime(state.time);
        break;
      case 'date':
        hasError = !validateDate(state.date);
        break;
      default:
        hasError = true;
        break;
    }
    setFieldError(_objectSpread(_objectSpread({}, fieldError), {}, {
      [field]: hasError
    }));
    return hasError;
  };
  const validateForm = () => {
    const validatedFields = {
      name: validateField('name'),
      email: validateField('email'),
      tournamentName: validateField('tournamentName'),
      password: validateField('password'),
      time: validateField('time'),
      date: validateField('date')
    };
    setFieldError(validatedFields);
    const isFormValid = Object.values(validatedFields).every(err => err === false);
    return isFormValid;
  };
  const onSubmit = e => {
    e.preventDefault();
    const isFormValid = validateForm();
    if (isFormValid) {
      setIsPosting(true);
      handleSubmit(state, () => {
        dispatch({
          type: actions.RESET_FORM
        });
        setIsPosting(false);
      }, () => {
        setIsPosting(false);
      });
    } else {
      formRef === null || formRef === void 0 || formRef.current.scrollIntoView();
    }
  };
  const isWideDevice = useMediaQuery(breakpoints.up(BREAKPOINT_MD));
  const renderRadioGroups = () => {
    if (isWideDevice) {
      return React.createElement(React.Fragment, null, React.createElement(ControlGroup, {
        className: "col-xs-12 col-lg-6",
        v2: true
      }, React.createElement(Fieldset, {
        legend: "Bordsstorlek"
      }, React.createElement(RadioGroup, {
        branding: "poker-react",
        id: "table-size",
        size: "200"
      }, TableSize.map(_ref => {
        let {
          id,
          title
        } = _ref;
        return React.createElement(RadioButton, {
          branding: "poker-react",
          checked: id === state.tableSize,
          key: id,
          minWidth: "140px",
          name: id,
          onChange: () => dispatch({
            type: actions.SET_TABLE_SIZE,
            payload: id
          }),
          value: state.tableSize
        }, title);
      })))), React.createElement(ControlGroup, {
        className: "col-xs-12 col-lg-6",
        v2: true
      }, React.createElement(Fieldset, {
        legend: "Typ av turnering"
      }, React.createElement(RadioGroup, {
        branding: "poker-react",
        id: "game-type",
        size: "200"
      }, GameTypes.map(_ref2 => {
        let {
          id,
          title
        } = _ref2;
        return React.createElement(RadioButton, {
          branding: "poker-react",
          checked: id === state.gameType,
          key: id,
          minWidth: "140px",
          name: id,
          onChange: () => dispatch({
            type: actions.SET_GAME_TYPE,
            payload: id
          }),
          value: state.gameType
        }, title);
      })))));
    }
    return React.createElement(React.Fragment, null, React.createElement(TournamentSelect, {
      id: "table-size",
      label: "Bordsstorlek",
      onChange: e => dispatch({
        type: actions.SET_TABLE_SIZE,
        payload: e.target.value
      }),
      options: TableSize,
      value: state.tableSize
    }), React.createElement(TournamentSelect, {
      id: "game-type",
      label: "Typ av turnering",
      onChange: e => dispatch({
        type: actions.SET_GAME_TYPE,
        payload: e.target.value
      }),
      options: GameTypes,
      value: state.gameType
    }));
  };
  if (!isLoggedIn) {
    return React.createElement(PokerPaper, {
      className: "tournament-registration-form-container login-container"
    }, React.createElement("p", {
      className: "f-500 f-center"
    }, "Logga in f\xF6r att boka ett bord"), React.createElement(Button, {
      block: !isWideDevice,
      className: "btn-poker login-btn",
      onClick: () => svs.components.customer_login.login()
    }, "Logga in"));
  }
  return React.createElement(PokerPaper, {
    className: "tournament-registration-form-container",
    ref: formRef
  }, React.createElement(Form, {
    className: "tournament-registration-form",
    isVertical: true,
    onSubmit: onSubmit
  }, React.createElement("div", {
    className: "grid-row gutter-xs-1"
  }, React.createElement(ControlGroup, {
    className: "col-xs-12 col-md-6 text-input",
    errorMessage: "Du m\xE5ste ange ditt namn",
    hasError: fieldError.name,
    v2: true
  }, React.createElement(Input, {
    autoComplete: "off",
    disabled: nameFromServer !== null,
    inverted: true,
    label: "Namn",
    name: "name",
    onBlur: () => validateField('name'),
    onChange: e => dispatch({
      type: actions.SET_NAME,
      payload: e.target.value
    }),
    value: state.name
  })), React.createElement(ControlGroup, {
    className: "col-xs-12 col-md-6 text-input",
    errorMessage: "Du m\xE5ste ange en giltig e-post.",
    hasError: fieldError.email,
    v2: true
  }, React.createElement(Input, {
    autoComplete: "off",
    disabled: emailFromServer !== null,
    inverted: true,
    label: "E-Post",
    name: "email",
    onBlur: () => validateField('email'),
    onChange: e => dispatch({
      type: actions.SET_EMAIL,
      payload: e.target.value
    }),
    value: state.email
  })), React.createElement(ControlGroup, {
    className: "col-xs-12 col-md-6 text-input",
    errorMessage: "Ange 4-27 tecken, A-\xD6 och 0-9, ej specialtecken",
    hasError: fieldError.tournamentName,
    v2: true
  }, React.createElement(Input, {
    autoComplete: "off",
    inverted: true,
    label: "Namn p\xE5 turnering",
    name: "tournament-name",
    onBlur: () => validateField('tournamentName'),
    onChange: e => dispatch({
      type: actions.SET_TOURNAMENT_NAME,
      payload: e.target.value
    }),
    prefix: "(P)",
    value: state.tournamentName
  })), React.createElement(ControlGroup, {
    className: "col-xs-12 col-md-6 text-input",
    errorMessage: "Ange 4-8 tecken, A-\xD6 och 0-9, ej specialtecken",
    hasError: fieldError.password,
    v2: true
  }, React.createElement(Input, {
    autoComplete: "off",
    inverted: true,
    label: "L\xF6senord",
    name: "password",
    onBlur: () => validateField('password'),
    onChange: e => dispatch({
      type: actions.SET_PASSWORD,
      payload: e.target.value
    }),
    value: state.password
  })), React.createElement(ControlGroup, {
    className: "col-xs-12 col-md-6 date-time",
    errorMessage: "Datum m\xE5ste v\xE4ljas",
    hasError: fieldError.date,
    v2: true
  }, React.createElement(DateInput, {
    inverted: true,
    min: dateFns.addDays(new Date(), 5).toISOString().split('T')[0],
    name: "date",
    onBlur: () => validateField('date'),
    onChange: e => dispatch({
      type: actions.SET_DATE,
      payload: e.target.value
    }),
    value: state.date
  })), React.createElement(ControlGroup, {
    className: "col-xs-12 col-md-6 date-time",
    errorMessage: "Tid m\xE5ste v\xE4ljas",
    hasError: fieldError.time,
    v2: true
  }, React.createElement(TimeInput, {
    errorMessageDefault: "",
    errorMessageRequired: "",
    inverted: true,
    name: "time",
    onBlur: () => validateField('time'),
    onChange: e => dispatch({
      type: actions.SET_TIME,
      payload: e.target.value
    }),
    value: state.time
  })), renderRadioGroups(), React.createElement(TournamentSelect, {
    id: "blinds",
    label: "M\xF6rkerstruktur",
    onChange: e => dispatch({
      type: actions.SET_BLINDS,
      payload: e.target.value
    }),
    options: Blinds,
    value: state.blinds
  }), React.createElement(TournamentSelect, {
    id: "stack",
    label: "Startmarker",
    onChange: e => dispatch({
      type: actions.SET_STACK,
      payload: e.target.value
    }),
    options: Stack,
    value: state.stack
  }), React.createElement(TournamentSelect, {
    id: "buyin",
    label: "Ink\xF6p",
    onChange: e => dispatch({
      type: actions.SET_BUYIN,
      payload: e.target.value
    }),
    options: Buyin,
    value: state.buyin
  }), React.createElement(TournamentSelect, {
    id: "late-registration",
    label: "Sen registrering",
    onChange: e => dispatch({
      type: actions.SET_LATE_REGISTRATION,
      payload: e.target.value
    }),
    options: LateRegistration,
    value: state.lateRegistration
  }), React.createElement(TournamentSelect, {
    id: "time-bank",
    label: "Tidsbank",
    onChange: e => dispatch({
      type: actions.SET_TIMEBANK,
      payload: e.target.value
    }),
    options: TimeBank,
    value: state.timeBank
  }), React.createElement(TournamentSelect, {
    id: "re-entry",
    label: "Re-entry",
    onChange: e => dispatch({
      type: actions.SET_RE_ENTRY,
      payload: e.target.value
    }),
    options: ReEntry,
    value: state.reEntry
  })), React.createElement(Button, {
    block: true,
    className: "btn-poker submit-btn",
    disabled: isPosting,
    type: "submit"
  }, "Registrera turnering")));
};
setGlobal('svs.poker_react.components.tournamentRegistrationForm.TournamentRegistrationForm', TournamentRegistrationForm);

 })(window);