(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/tournament-registration-form/assets/javascripts/utils.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/tournament-registration-form/assets/javascripts/utils.js');
"use strict";

const log = new svs.core.log.Logger('svs.poker_react.components.tournamentRegistrationForm');
const {
  GameTypes,
  Blinds,
  Stack,
  Buyin,
  LateRegistration,
  TimeBank,
  ReEntry,
  TableSize
} = svs.poker_react.components.tournamentRegistrationForm.constants;
const showModal = function (title, icon, text) {
  let type = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'DEFAULT';
  const dialogOptions = {
    branding: svs.components.dialog.branding.SPORT,
    title,
    icon,
    area: svs.components.dialog.area.POPUP,
    type: svs.components.dialog.type[type],
    product: 'Poker',
    message: [{
      type: svs.components.dialog.message.TEXT,
      text
    }],
    actions: [{
      title: 'Okej',
      callback: () => {
        dialog.close();
      }
    }]
  };
  const dialog = new svs.components.dialog.Confirm(dialogOptions);
  svs.components.dialog.api.add(dialog);
};
const handleSubmit = (formData, onSuccess, onError) => {
  const data = {
    blinds: Blinds.find(blind => blind.id === formData.blinds).title,
    buyin: Buyin.find(buyin => buyin.id === formData.buyin).title,
    date: formData.date,
    email: formData.email,
    stack: Stack.find(stack => stack.id === formData.stack).title,
    time: formData.time,
    'tournament-password': formData.password,
    'player-name': formData.name,
    'tournament-name': "(P)".concat(formData.tournamentName),
    timebank: TimeBank.find(timeBank => timeBank.id === formData.timeBank).title,
    'late-registration': LateRegistration.find(lateRegistration => lateRegistration.id === formData.lateRegistration).title,
    type: GameTypes.find(gameType => gameType.id === formData.gameType).title,
    're-entry': ReEntry.find(reEntry => reEntry.id === formData.reEntry).title,
    tablesize: TableSize.find(tableSize => tableSize.id === formData.tableSize).title
  };
  const options = {
    method: 'PUT',
    path: '/supportive/1/pokerbookingmessages',
    data
  };
  svs.core.jupiter.call(options, () => {
    showModal('Turneringen är beställd', 'information', 'Vi återkommer med en bekräftelse.');
    onSuccess();
  }, error => {
    showModal('Något gick fel vid registreringen', 'help-2', 'Försök igen och kontakta vår kundservice om felet kvarstår.', 'ERROR');
    log.error('Error registering poker tournament.', error);
    onError();
  });
};
setGlobal('svs.poker_react.components.tournamentRegistrationForm.utils', {
  handleSubmit
});

 })(window);